<!-- =========================================================================================
    File Name: designationForm.vue
    Description: Work Type
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- ADD Work Type FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Work Type">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Work Type Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Work Type Name"
                        v-model="form.project_title"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Work Type Name')"
                        >{{ errors.first("Work Type Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Notification Day*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Notification Day"
                        v-model="notification_days"
                        class="w-full"
                        type="number"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Notification Day')"
                        >{{ errors.first("Notification Day") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- Must have a value that is in the specified list : 1 or 3 -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-switch v-model="switch1" />
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import WorkTypeService from "@/services/workTypeService.js";
import CompanyService from "@/services/companyService.js";
import axios from "axios";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { BASEURL } from "@/config/index.js";
export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: true,
      switch1: true,
      designation_description: "",
      form: {
        project_title: "",

        work_type_active: 1,
      },
      notification_days: 0,

      value: [],
      companies: [],
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },

    submitForm() {
      // if (this.notification_days < 0) {
      //   this.$vs.notify({
      //     title: "Error!",
      //     text: "Please Enter Notification Days",
      //     iconPack: "feather",
      //     icon: "check_box",
      //     color: "warning"
      //   });
      //   return false;
      // }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          this.submitStatus = false;
          let payload = {
            work_type_id: this.form.work_type_id,
            project_title: this.form.project_title,
            notification_days: this.notification_days,
            designation_description: this.designation_description,
            work_type_active: this.switch1 ? "1" : "2",
          };
          // console.log("payload :", payload);
          WorkTypeService.addWorkType(payload)
            .then((response) => {
              this.submitStatus = false;

              const { data } = response;
              this.$vs.loading.close();

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
                setTimeout(() => {
                  eventBus.$emit("refreshTableworkType", data);
                }, 1);
              } else {
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.designation_description = "";
      this.notification_days = 0;

      this.value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    // this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
